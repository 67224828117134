import { createRef, forwardRef, useEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { Icon } from "~assets";
import { cn } from "~utils";

type OwnProps = {
  indeterminate?: boolean;
  isInvalid?: boolean;
};

type Props = OwnProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      indeterminate,
      isInvalid,
      /**
       * For the cases where we use uncontrolled checkboxes,
       * this empty event handler is needed to make react happy.
       */
      onChange = () => {},
      ...rest
    },
    ref,
  ) => {
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
      inputRef.current?.setCustomValidity(isInvalid ? "error" : "");
    }, [inputRef, isInvalid]);

    // Indeterminate is a weird semi-native attribute of checkbox input.
    // it is supported in css and documented, but _must_ be set by javascript.
    useEffect(() => {
      if (!inputRef?.current) return;
      inputRef.current.indeterminate = indeterminate ?? false;
    }, [inputRef, indeterminate]);

    return (
      <label className="relative m-0 inline-grid h-4 w-4 place-content-center overflow-hidden rounded p-0.5 text-white-100">
        <input
          ref={mergeRefs([ref, inputRef])}
          type="checkbox"
          className={cn(
            "peer absolute left-0 top-0 m-0 h-full w-full appearance-none overflow-hidden rounded border border-solid border-grey-700 bg-white-100 text-current",

            "before:absolute before:-left-1/4 before:-top-1/4 before:block before:h-[150%] before:w-[150%] before:rounded-full before:content-['']",

            "checked:scale-100 checked:transform checked:border-green-400 checked:bg-green-400 checked:transition-transform checked:duration-150 checked:ease-out",
            "checked:before:scale-100 checked:before:transform  checked:before:bg-green-400 checked:before:transition-transform checked:before:duration-150 checked:before:ease-out ",

            "indeterminate:scale-100 indeterminate:transform indeterminate:border-green-400 indeterminate:transition-transform indeterminate:duration-150 indeterminate:ease-out",
            "indeterminate:before:scale-100 indeterminate:before:transform indeterminate:before:bg-green-400 indeterminate:before:transition-transform indeterminate:before:duration-150 indeterminate:before:ease-out",

            "disabled:border-grey-600",

            "invalid:border-red-700",
            "checked:invalid:before:bg-red-700",
          )}
          onChange={onChange}
          {...rest}
        />
        <Icon
          name={indeterminate ? "dash" : "check"}
          className={cn(
            "absolute left-1/2 top-1/2 hidden h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 transform",
            "peer-checked:block peer-indeterminate:block",
            "pointer-events-none peer-disabled:text-white-100",
          )}
        />
      </label>
    );
  },
);

Checkbox.displayName = "Checkbox";
